@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500&display=swap");

.button-primary:hover,
.button-primary:active,
.button-primary-active,
.button-primary-inactive {
  background-color: #c8d6f1;
  border: 1px solid #c8d6f1;
}

.button-primary-active,
.button-primary-inactive {
  color: #152d4a;
}

.app {
  text-align: center;
  /* background-color: red; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  font-family: "IBM Plex Mono", monospace;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* height: 100%; */
}

.time {
  font-size: 3rem;
  /* padding: 6rem; */
}

.timer-width {
  min-width: 100px;
  /* padding: 3em; */
  text-align: center;
}

.button {
  padding: 0.6rem 1.5rem;
  margin: 0.4rem;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  border-style: groove;
  width: 7em;
}

.button:focus {
  outline-width: 0;
}

.timer-circle {
  font-size: 7rem;
  height: 25rem;
  width: 25rem;
  background-color: #d3deba;
  border-radius: 45% 55% 50% 50% / 50% 50% 45% 55%;
  line-height: 22.5rem;
}

.row {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.circle-button {
  padding: 1rem;
  border-radius: 43% 57% 48% 52% / 47% 43% 57% 53%;
}

.circle-button:hover {
  cursor: pointer;
}
