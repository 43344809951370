$background-color-light: rgba(255, 255, 255, 0.1);
$background-color-dark: rgba(0, 0, 0, 0.5);
$background-color-semi-dark: rgba(15, 15, 15, 0.7);
$primary-text-color: black;
$secondary-text-color: white;
$button-background-color: #d9dadd;
$button-border-radius: 100rem;
$button-box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
$button-hover-transition: transform 0.3s ease-in-out;
$button-hover-transform: scale(1.1);
$icon-color: #3b3b3b;
$icon-fill: #c5c5c5;
$lower-nav-background-color: (15, 15, 15, 0.7);
$nav-icon-padding: 1rem 2rem;
$nav-icon-border-radius: 1rem;
$nav-icon-hover-transform: transform 0.3s ease-in-out;
$nav-icon-hover-color: black;
$nav-icon-height: 3rem;
$nav-icon-width: 3rem;
$font-family: "Quicksand", sans-serif;
$font-size-small: 1.4rem;
$font-size-medium: 1.8rem;
$font-size-large: 2.4rem;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin background-image($url, $size: cover) {
  background-image: linear-gradient(
      #{$background-color-light},
      #{$background-color-light}
    ),
    url(#{$url});
  background-size: #{$size};
}

@mixin background-image-dark($url, $size: cover) {
  background-image: linear-gradient(
      #{$background-color-dark},
      #{$background-color-dark}
    ),
    url(#{$url});
  background-size: #{$size};
}

@mixin background-image-semi-dark($url, $size: cover) {
  background-image: linear-gradient(
      #{$background-color-semi-dark},
      #{$background-color-semi-dark}
    ),
    url(#{$url});
  background-size: #{$size};
}

@mixin button($width: 12rem, $height: 3.5rem) {
  color: #{$icon-color};
  width: #{$width};
  height: #{$height};
  background-color: #{$button-background-color};
  border: none;
  border-radius: #{$button-border-radius};
  box-shadow: #{$button-box-shadow};
  font-weight: 300;
  font-family: #{$font-family};
  font-size: #{$font-size-small};

  &:hover {
    cursor: pointer;
    transition: #{$button-hover-transition};
    transform: #{$button-hover-transform};
  }
}

@mixin nav-icon($color: #{$icon-color}, $size: 3rem) {
  color: #{$color};
  font-size: #{$size};
  padding: #{$nav-icon-padding};
  border-radius: #{$nav-icon-border-radius};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  &:hover {
    cursor: pointer;
    transition: #{$nav-icon-hover-transform};
    transform: #{$nav-icon-hover-transform};
    color: #{$nav-icon-hover-color};
  }
}

/////

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

h3 {
  color: black;
}
.home-container {
  height: 100vh;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(../../public/home.png);

  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.signup-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../public/signup.png);
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.builder-container {
  height: 100vh;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(../../public/home.png);

  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.login-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../../public/login.png);
  height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settings-container {
  height: 100vh;
  background-image: linear-gradient(
      rgba(15, 15, 15, 0.7),
      rgba(15, 15, 15, 0.7)
    ),
    url("../../public/signup.png");
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upper-nav {
  font-family: "Quicksand", sans-serif;
  display: flex;
  color: #3b3b3b7a;
  justify-content: flex-start;
  width: 100vw;
  margin-top: 2rem;
}

.upper-nav-home {
  display: flex;
  gap: 3rem;
  color: #3b3b3b;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 2rem;
}

.header {
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  padding: 1rem;
  display: flex;
  font-weight: bold;
}

.welcome-name {
  font-family: "Quicksand", sans-serif;
  font-size: 2.4rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  gap: 1rem;
}

.user-icon {
  height: 2.5rem;
  width: 2.5rem;
  stroke: #3b3b3b;
  fill: #3b3b3b;
}

.header-save-mood {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.save-mood-btn {
  font-size: 1.2rem;
  color: #3b3b3b;
  width: 12rem;
  height: 3.5rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  font-weight: 300;
}

.save-mood-btn:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}
.save-schedule-btn {
  margin-bottom: 8rem;
  color: #3b3b3b;
  width: 25rem;
  height: 4rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  font-size: 1.8rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  font-weight: 300;
}

.save-schedule-btn:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.signout-icon {
  height: 3rem;
  width: 3rem;
}

.settings-icon {
  height: 3rem;
  width: 3rem;
}

.settings-icon:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.signout-icon:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.lower-nav {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Quicksand", sans-serif;
}

.nav-icon {
  font-size: 1.4rem;
  background-color: (15, 15, 15, 0.7);
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
}

.nav-icon-builder {
  color: #3b3b3b;
  font-size: 2.4rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-icon-builder:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
  color: black;
}

.icon-label {
  color: #57534e;
}

.nav-icon:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
  color: black;
}

.lower-icon {
  height: 3rem;
  width: 3rem;
}

.lower-icon-plus {
  height: 4rem;
  width: 4rem;
}
.lower-icon-builder {
  height: 3rem;
  width: 3rem;
}

.trash-icon {
  fill: #c5c5c5;
}

.background-icon {
  fill: #c5c5c5;
}

.music-icon {
  fill: #c5c5c5;
}

.builder-icon {
  fill: #c5c5c5;
}

.today-icon {
  fill: #c5c5c5;
}

.timer-icon {
  fill: #c5c5c5;
}

.goals-icon {
  fill: #c5c5c5;
}

.morningq-icon {
  fill: #c5c5c5;
}

.list-icon {
  fill: #c5c5c5;
}

.timer-icon {
  fill: #c5c5c5;
}

.projector-icon {
  fill: #c5c5c5;
}

.today-date {
  font-family: "Quicksand", sans-serif;
  font-size: 5rem;
  font-weight: bold;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: auto;
}

.header-login {
  font-size: 4.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.login-form {
  margin-top: 3rem;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.login-inputs {
  margin-bottom: 1.5rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 1.6rem;
  font-size: 1.4rem;
  width: 30rem;
}

.login-buttons {
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 1rem;
  font-size: 1.4rem;
  width: 30rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.login-buttons:hover {
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.or {
  font-size: 1.8rem;
  color: white;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
}

.login-label {
  font-size: 1.8rem;
  color: white;
  font-family: "Quicksand", sans-serif;
}

.email-container,
.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

/* SIGN UP PAGE */

.header-signup {
  font-size: 4.8rem;
  color: white;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.header-signup-subtext {
  color: white;
  font-size: 1.4rem;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 2rem;
  gap: 1rem;
}

.name-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-inputs {
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 1rem;
  width: 25rem;
  font-size: 1.4rem;
}

.signup-button {
  font-weight: bold;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
  font-family: "Quicksand", sans-serif;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 1rem;
  font-size: 1.4rem;
  width: 22rem;
}

.signup-button:hover {
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.back-to-login {
  font-weight: bold;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
  font-family: "Quicksand", sans-serif;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 1rem;
  font-size: 1.4rem;
  width: 22rem;
  margin-top: 3rem;
}

.back-to-login:hover {
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.signup-htmlForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

/* SETTINGS PAGE */

.header-settings {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.header-settings-name {
  font-size: 4.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  color: white;
}

.settings-name {
  font-size: 1.6rem;
  font-family: "Quicksand", sans-serif;
  color: white;
  white-space: nowrap;
}

.settings-name-signout {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.settings-signout-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  font-size: 1.4rem;
  border-radius: 10rem;
  cursor: pointer;
  transition: 0.3s;
  padding: 0.4rem 1.2rem 0.4rem;
  background-color: rgb(217, 218, 221, 0.8);
}

.settings-signout-btn:hover {
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.settings-back {
  height: 6.4rem;
  width: 6.4rem;
  cursor: pointer;
}
.settings-back:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.settings {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 2rem;
}

.my-account-info {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
}

.my-account-info-password {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
}

.account-input {
  padding-left: 1rem;
  margin-left: 1rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 0.5rem;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  width: 20rem;
}

.account-input-password {
  padding-left: 1rem;
  margin-left: 1rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 0.5rem;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  width: 20rem;
}
.account-box {
  font-family: "Quicksand", sans-serif;

  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: flex-start;
  justify-content: center;
}

// .delete-account,
// .change-password,
// .save-changes {
//   color: #5a5a5a;
//   background-color: #d9dadd;
//   border: none;
//   border-radius: 50rem;
//   padding: 0.4rem;
//   font-size: 1.6rem;
//   width: 15rem;
// }

// .delete-account:hover,
// .change-password:hover,
// .save-changes:hover {
//   color: black;
//   cursor: pointer;
//   font-family: "Quicksand", sans-serif;
//   box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s ease-in-out;
//   transform: scale(1.05);
// }
.settings-account-btns {
  display: flex;
  gap: 1rem;
}

.account-email {
  font-size: 1.6rem;
  margin-left: 1rem;
}
.my-account-info-email {
  font-size: 1.6rem;
}

div[contentEditable="true"] {
  user-select: text;
}
div[contentEditable="true"] {
  cursor: text;
}

.next-icon,
.prev-icon {
  margin-right: 3rem;
  margin-left: 3rem;
  color: grey;
  height: 3rem;
  width: 3rem;
}

.prev-icon:hover,
.next-icon:hover {
  cursor: pointer;
}

.text {
  cursor: text;
  padding-left: 4rem;
  padding-right: 4rem;
}

.checkbox {
  margin-right: 3rem;
}
input[type="checkbox"] {
  accent-color: #3b3b3b;
}

.dayOfWeek {
  color: black;
}

.schedule-builder-display {
  display: flex;
  height: 40rem;
  width: 90vw;
  gap: 4rem;
  font-family: "Quicksand", sans-serif;
}

.today-schedule-display {
  display: flex;
}

.resets {
  font-family: "Quicksand", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  gap: 2rem;
}

.sets-resets-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-text {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.reset-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.reset-morningq,
.reset-haha {
  color: #5a5a5a;
  background-color: #d9dadd;
  border: none;
  border-radius: 50rem;
  padding: 1rem;
  font-size: 1.2rem;
  width: 20rem;
}

.reset-morningq:hover,
.reset-haha:hover {
  color: black;
  cursor: pointer;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.set-font-input {
  padding-left: 1rem;
  margin-left: 1rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 0.5rem;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  width: 3rem;
}

.set-font-label,
.set-timer-start-time {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
}

.set-font-input-HaQ {
  padding-left: 1rem;
  margin-left: 1rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  padding: 0.5rem;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.4rem;
  width: 3rem;
}

.set-font-label-HaQ {
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
}

.sets-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-message {
  color: white;
  font-weight: bold;
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
}

.builder-dayOfWeek {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.builder-dayOfWeek-text {
  font-size: 2rem;
  color: black;
}

.builder-input {
  height: 50vh;
  width: 13vw;
}

.save-all-changes {
  font-size: 2.4rem;
  color: hsl(0, 0%, 23%);
  width: 25rem;
  height: 8rem;
  background-color: #d9dadd;
  border: none;
  border-radius: 100rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  font-weight: 300;
  margin-top: 4rem;
}

.save-all-changes:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.save-changes {
  color: #5a5a5a;
  background-color: #d9dadd;
  border: none;
  border-radius: 50rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  width: 15rem;
}

.save-changes:hover {
  color: black;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.edit-changes {
  color: #5a5a5a;
  background-color: #d9dadd;
  border: none;
  border-radius: 50rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  width: 15rem;
}

.edit-changes:hover {
  color: black;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.save-changes-builder {
  margin-top: 2rem;
  color: #5a5a5a;
  background-color: #d9dadd;
  border: none;
  border-radius: 50rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  width: 15rem;
}

.save-changes-builder:hover {
  color: black;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.edit-changes-builder {
  margin-top: 2rem;
  color: #5a5a5a;
  background-color: #d9dadd;
  border: none;
  border-radius: 50rem;
  padding: 0.4rem;
  font-size: 1.6rem;
  width: 15rem;
}

.edit-changes-builder:hover {
  color: black;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  box-shadow: 0 0 3.2rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.05);
}

.landing-container {
  // position: relative;
  background-image: linear-gradient(
    to right,
    rgba(209, 201, 88, 1),
    rgba(240, 232, 72, 0.7)
  );
  height: 100%;
  color: white;
}
.video-background {
  position: absolute;
  top: 100;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.landing-mm {
  color: white;
  font-family: "Quicksand", sans-serif;
  font-size: 3.2rem;
}

.landingNav-right {
  display: flex;
  gap: 3rem;
  font-family: "Quicksand", sans-serif;
  font-size: 2.8rem;
}

.nav {
  font-weight: bold;
  color: white;
  text-decoration: none; /* Remove underline on hover */
}
.nav:hover {
  color: white;
  text-decoration: underline;
  color: #4c6317;
}

.landing-nav {
  background-color: red;
}

.landing-main {
  width: 60vw;
  margin-top: 5rem;
  color: white;
  font-family: "Quicksand", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.landing-headline {
  font-size: 2.8rem;
  font-weight: bold;
}

.landing-sub-headline {
  font-size: 6.2rem;
}

.landing-sub-headline-2 {
  font-size: 3.2rem;
  font-weight: bold;
}
.nav-article {
  color: white;
  font-family: "Quicksand", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.article-why {
  font-size: 6.2rem;
}

.article-headline {
  font-family: "Quicksand", sans-serif;
  font-size: 2.4rem;
  width: 60vw;
  background-color: #4c6317;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.steps-headline {
  display: flex;
  gap: 2rem;
  font-size: 2.4rem;
  width: 60vw;
  background-color: #4c6317;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  margin-bottom: 3rem;
  margin-top: 3rem;
}

a {
  text-decoration: none;
  color: rgba(15, 15, 15, 0.7);
}
a:hover {
  text-decoration: none;
  color: rgba(15, 15, 15, 0.7);
}

.nav-section {
  color: white;
  font-family: "Quicksand", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.section-how-it-works {
  font-size: 6.2rem;
}

.landingNav {
  color: rgba(15, 15, 15, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;

  // background-color: rgba(209, 201, 88);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(209, 201, 88, 1)),
    url(../../public/login.png);
  background-size: cover;
  z-index: 1;
}

.video-step1 {
  border-radius: 1rem;
  height: 50%;
  width: 50%;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.video-step2 {
  border-radius: 1rem;
  height: 50%;
  width: 50%;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.step3 {
  padding: 1rem 2rem;
  width: 60vw;
  font-size: 2.2rem;
  font-weight: bold;
  border-radius: 1rem;
  color: #4c6317;
  // background-color: rgba(255, 255, 255, 0.5);
  // box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.research-article {
  height: 25rem;
  width: 20rem;
  color: #010103aa;

  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.research-container {
  display: flex;
  gap: 3rem;
  padding: 3rem;
}

.research-all {
  display: flex;
}

.journal-headline {
  margin: 2rem;
  font-family: "Quicksand", sans-serif;
  font-size: 2.4rem;
  width: 60vw;
  background-color: #4c6317;
  border: none;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
}

.good-to-start {
  padding-top: 2rem;
  text-align: center;
  font-size: 4.2rem;
  color: #4c6317;
}

.line-break {
  height: 2rem;
  width: 60vw;
  background-color: #4c6317;
  border-radius: 2rem;
  margin: 2rem;
}

.used-by-text {
  font-family: "Quicksand", sans-serif;
  font-size: 4.8rem;
  padding: 2rem;
}

.district-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  padding: 2rem;
}

.district-logo {
  border-radius: 1rem;
  height: 10rem;
  width: 10rem;
}

.footer {
  padding: 5rem;
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  display: flex;
  gap: 10rem;
}

.footer-contact {
  color: white;
}

.footer-contact:hover {
  text-decoration: underline;
  color: #4c6317;
}

.contact-container {
  // position: relative;
  background-image: linear-gradient(to right, #d1c958, rgba(240, 232, 72, 0.7));
  height: 100%;
  color: white;
}

.contact-text {
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  width: 30vw;
  padding: 2rem;
}

.contact-form {
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  width: 50vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.label-input-name,
.label-input-email {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.label-input-message {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.input-name,
.input-email {
  height: 2.5rem;
  border-radius: 1rem;
  border: none;
  padding: 1rem;
}

.input-message {
  height: 10rem;
  border-radius: 1rem;
  border: none;
  padding: 1rem;
}

.contact-submit {
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  padding: 1rem 4rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 30vw;
  border-radius: 1rem;
  border: none;
}

.contact-submit:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
  background-color: #4c6317;
  color: white;
}

.google-form {
  margin: 2rem;
}

.small-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.builder-signout-btn {
  border: none;
  width: 10rem;
  font-size: 1.4rem;
  border-radius: 10rem;
  padding: 0.4rem 1.2rem 0.4rem;
  background-color: rgb(217, 218, 221, 0.8);
}

.builder-signout-btn:hover {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.nav-message-builder {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 7rem;
  font-size: 1.8rem;
  color: black;
}

.hidden {
  display: none;
}

.edit-changes-builder {
  margin-bottom: 2rem;
}
// MEDIA QUERIES
@media (max-width: 67.7rem) {
  .landingNav {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  .landingNav-right {
    font-size: 1.8rem;
  }
  .video-step1 {
    height: 80%;
    width: 80%;
  }
  .video-step2 {
    height: 80%;
    width: 80%;
  }
  .research-container {
    flex-direction: column;
  }
  .contact-text {
    width: 80vw;
  }
  .schedule-builder-display {
    flex-direction: column;
  }
  .builder-input {
    width: 70vw !important;
  }
  .nav-icon-builder {
    display: none;
  }
  .small-screen-container {
    display: flex;
  }
  .builder-container {
    display: block;
  }
  .builder-signout-btn {
    margin-bottom: 3rem;
  }
}
